import { Card } from 'react-bootstrap'
import './Brandilupaukset.css'

function ServiceBlock({ image, title, description }) {
    return (
        <Card className="p-0" style={{ maxWidth: '400px', border: 'none', backgroundColor: '#fffcf7', transition: 'transform 0.3s ease-in-out', }}
        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}>
            <Card.Img
                variant="top"
                src={image}
                alt={title}
                className="mb-3 img-fluid"
                style={{
                    maxHeight: '150px',
                    width: '100%',
                    objectFit: 'contain',
                    
                }}
            />
            <Card.Body>
                <Card.Title className="mb-0 brandilupaukset-card-title" style={{ minHeight: '97px' }}>{title}</Card.Title>
                <Card.Text className="my-0 brandilupaukset-card-text" style={{ minHeight: '100px' }}>
                    {description}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default ServiceBlock