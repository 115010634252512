import brändistrategia from '../../../assets/images/brändistrategia.jpg'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'
import brändiuudistus from '../../../assets/images/brändiuudistus.png'
import './Hero.css'

function Hero() {

    return (
        <Container fluid className="hero">
            <Row>
                <Col className="hero-left-content d-lg-flex d-none">
                    <Image fluid
                        src={brändistrategia}
                        className='hero-image mx-auto' />
                </Col>
                <Col className='mx-5'>
                    <h2 className='hero-text-h2'>Kuka on Brändihuvilan perustaja ja brändistrategi Jenni?</h2>
                    <div className='d-lg-none d-flex justify-content-center align-items-center'>
                    <Image fluid
                        src={brändistrategia}
                        className='hero-image' />
                    </div>

                    <p className='hero-text-p'> Moi! Kiva kun oot täällä. Ajatus Brändihuvilan perustamisesta syntyi puhtaasta
                        intohimosta brändäystä kohtaan. Opiskelin markkinointia Oulun yliopistossa ja
                        tutkin opintojeni aikana huippuarvosanoin mm. toimitusjohtajan henkilöbrändäystä
                        sekä yritysbrändien rakentamista. Aika mehukkaita aiheita!
                    </p>
                    <p className='hero-text-p hero-text-mobile1'>
                        Olen aina työskennellyt ihmisten kanssa, ja se on ehdottomasti vahvuuteni.
                        Koko opintojeni ajan työskentelin suomalaisessa kasvuyrityksessä erilaisissa
                        rooleissa aina markkinoinnista kumppaniverkoston hallintaan ja johtamiseen.
                        Erityisesti kasvuyrityksessä brändin rakentaminen on yksi tärkeimpiä prioriteetteja, ja onhan se myös äärimmäisen mielenkiintoista - ja koukuttavaa.
                    </p>
                    <p className='hero-text-p hero-text-mobile2'>
                        Niinpä syntyi ajatus Brändihuvilasta. <strong>Paikasta,</strong> jossa voin puhua lempiaiheestani, brändäyksestä, joka ikinen päivä.
                        <strong> Visiosta,</strong> jossa suomalaiset tunnetaan tulevaisuudessa brändäyksen asiantuntijoina.
                        <strong> Yrityksestä,</strong> jonka kautta pääsen auttamaan pieniä suomalaisia yrityksiä, joista on kasvamassa jotain suurta.
                    </p>
                    <p className='hero-signature'><strong>Jenni</strong></p>
                    <Row className='d-flex'>
                        <Col xs='auto' className='d-flex align-items-center justify-content-start'>
                            <HashLink to="#ota-yhteytta" className='tutustu-button btn btn-primary'>
                                Ota yhteyttä
                            </HashLink>
                        </Col>
                        <Col className='d-lg-flex d-none align-items-center justify-content-start'>
                            <p className='hero-tutustutaanko-lisaa text-center my-0'>
                               <strong>Vai tutustutaanko<br/>vielä lisää?</strong>
                            </p>
                            <Image fluid
                            className='brändiuudistuskuva'
                            src={brändiuudistus}
                            style={{ width: '100px', height: 'auto', transform: 'rotate(120deg)', marginBottom: '-100px' }}
                        />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Hero