import { Row, Col, Container } from "react-bootstrap"
import './Brandilupaukset.css'
import LupausBlock from "./LupausBlock"
import hourglass from '../../../assets/images/hourglass.png'
import iceberg from '../../../assets/images/iceberg.png'
import lamp from '../../../assets/images/lamp.png'
import bränditoimisto from '../../../assets/images/bränditoimisto.png'








function Brandilupaukset() {
    return (
        <section id="brandilupaukset">
            <Container fluid className="brandilupaukset-container mt-5">
                <Row className="text-center">
                    <h2 className="brandilupaukset-h2">Olisimmeko teille oikea bränditoimisto? Näin toimimme:</h2>
                </Row>
                <div className="container mt-5">
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xs={12} md={12} lg={3} className="d-flex text-center align-items-center justify-content-center">
                            <LupausBlock
                                image={hourglass}
                                title={
                                    <>
                                        Projekteillamme on <span style={{ color: '#38b6ff' }}>selkeä</span> aikataulu, suunnitelma ja päämäärä
                                    </>
                                }
                                description={
                                    <>
                                        Valitse vain teidän yritykselle sopivin paketti - olemme suunnitelleet kaiken valmiiksi.
                                    </>
                                }
                            />
                        </Col>
                        <Col xs={12} md={12} lg={3} className="d-flex text-center align-items-center justify-content-center">
                            <LupausBlock
                                image={bränditoimisto}
                                title={
                                    <>
                                        Vain rajallinen määrä <span style={{ color: '#38b6ff' }}>asiakaspaikkoja</span>
                                    </>
                                }
                                description={
                                    <>
                                        Meille mahtuu vain 10 asiakasta vuodessa. Siksi meillä ei ole ikinä kiire, vaan aikaa keskittyä aidosti teidän brändiinne.
                                    </>
                                }
                            />
                        </Col>
                        <Col xs={12} md={12} lg={3} className="d-flex align-items-center justify-content-center text-center">
                            <LupausBlock
                                image={iceberg}
                                title="Teemme brändityötä, joka ei jää pintapuoliseksi"
                                description={
                                    <>
                                        Keskitymme brändityössä sanallistamaan sen, mitä merkitystä juuri teidän brändi tuo asiakkaiden elämään. Miksi teihin halutaan sitoutua?
                                    </>
                                }
                            />
                        </Col>
                        <Col xs={12} md={12} lg={3} className="d-flex align-items-center justify-content-center text-center">
                            <LupausBlock
                                image={lamp}
                                title={
                                    <>
                                        Haluamme tehdä <span style={{ color: '#38b6ff' }}>teistä</span> brändäyksen ammattilaisia
                                    </>
                                }
                                description={
                                    <>
                                        Tavoitteenamme on tehdä meistä tarpeettomia yhteistyömme aikana. Sillä te, yrityksen sisällä, olette brändinne parhaita asiantuntijoita.
                                    </>
                                    }
                            />
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Brandilupaukset