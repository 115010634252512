import './Footer.css';
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'
import logovalkoinen from '../../../assets/images/logovalkoinen.png'

function Footer() {
    return (
        <footer className="footer">
            <Container fluid className="footerContainer">
                <Row className="footerContent align-items-end">

                    <Col xs={12} md={3} lg={3}>
                    <a href="/">
                        <Image
                            src={logovalkoinen}
                            alt="Brändihuvila"
                            className="logo-image-footer"
                        />
                        </a>
                    </Col>


                    <Col xs={12} md={3} lg={3} className="d-flex text-end">
                        <p>
                            Made by Mika
                        </p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="d-flex flex-column text-end align-items-end">
                        <p>
                            Brändihuvila Oy<br />
                            jenni@brandihuvila.fi<br />
                            Y-tunnus: 3477040-9
                        </p>
                        <Link
                            className="linkPrivacyPolicy"
                            to="/tietosuoja-ja-rekisteriseloste"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Tietosuoja- ja rekisteriseloste
                        </Link>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="d-flex flex-column align-items-end">
                        <HashLink className="links" to="#tarina">
                            Tietoa meistä
                        </HashLink>
                        <HashLink className="links" to="#palvelumme">
                            Palvelumme
                        </HashLink>
                        <HashLink className="links" to="#brandipodi">
                            Brändipodi
                        </HashLink>
                        <HashLink className="links" to="#ota-yhteytta">
                            Ota yhteyttä
                        </HashLink>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer