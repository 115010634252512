import { Carousel, Container } from "react-bootstrap"
import { useState } from "react"
import "./Feedback.css"

function Feedback() {
  const feedbacks = [
    {
      id: 1,
      text: `"Kirjoitin aiemmin valmiiksi ylös muutamia sanoja, jotka kuvaavat miten koin projektin ja minua palvelleen; asiantuntijan super ystävällinen, kannustava, helppoa, selkeää, luottamusta herättävää, tehokasta. Sain konkreettisia työkaluja ja motivaatiota, jäi kaiken kaikkiaan todella hyvä mieli tästä."`,
      service: "Palvelu: Brändimatka",
    },
    {
      id: 2,
      text: `"Erittäin ystävällinen ja kannustava kohtaaminen! Kaikki oli selkeää ja helppoa, koin tulleeni kuulluksi ja ymmärretyksi. Projektissa keskityttiin oleelliseen ja asiat tapahtuivat tehokkaasti."`,
      service: "Palvelu: Brändimatka"
    },
    {
      id: 3,
      text: `"Kiitos paljon näistä ja todellakin: odotan tämän matkan alkua aivan hirvittävällä innolla. Täytyy myöntää, etten muista olleeni pitkään aikaan mistään näin innoissani, kuin mitä olen tulevasta Brändimatkasta nyt! Aivan mahtavaa!"`,
      service: "Palvelu: Brändimatka"
    },
    {
      id: 4,
      text: `"Huh, loistavaa! Iso kiitos tästä. Tämän pohjalta päästään hyvin liikenteeseen, kun taas pääsee brändiasioita kehittämään."`,
      service: "Palvelu: Maksuton brändianalyysi"
    },
    {
      id: 5,
      text: `"Ihanaa, ihanaa, kiitos! On todella kiinnostavaa lukea miten asiantuntija-ammattilainen näkee ja kokee brändini visuaalisen näkymän. Annoit hyvää pohdittavaa, kiitos siitä."`,
      service: "Palvelu: Maksuton brändianalyysi"
    },
  ]

  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  return (
    <Container className="feedback-container" fluid>
      <h2 className="feedback-h2">Asiakaspalautteitamme:</h2>
      <Carousel
        className="carousel-wrapper"
        variant="dark"
        activeIndex={index}
        onSelect={handleSelect}
        indicators={false}
        controls={true}
        interval={5000}
        touch={true}
      >
        {feedbacks.map((feedback) => (
          <Carousel.Item className="carousel-item" key={feedback.id}>
            <div className="feedback-card">
              <p className="feedback-text">{feedback.text}</p>
              <p className="feedback-service">{feedback.service}</p>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  )
}

export default Feedback
