import brändäyspalvelu from '../../../assets/images/brändäyspalvelu.jpg'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'
import brändiuudistus from '../../../assets/images/brändiuudistus.png'
import './Brandiuudistus.css'

function Brandiuudistus() {

    return (
        <Container fluid className="brandiuudistus-container">
            <Row className='mt-5'>
                <Col className="mx-5 brandiuudistus-text-mobile">
                    <h2 className='brandiuudistus-h2'>Olisiko aika tehdä brändiuudistus, joka <span style={{ color: '#38b6ff' }}>ei</span> jää pintapuoliseksi?</h2>

                    <p className='brandiuudistus-text-p'>
                        Me tiedämme.
                        Brändiuudistus pelkästään jo sanana saattaa kuulostaa monimutkaiselta ja kalliilta. Vähän pelottavaltakin.
                        Mitä brändiuudistus vaatii, mistä brändi muodostuu ja muuttuuko kaikki kertaheitolla?
                    </p>
                    <p className='brandiuudistus-text-p'>
                        Me teemme brändin rakentamisesta <strong>yksinkertaista ja selkeää. </strong>
                        Brändiuudistus tarkoittaa meille ikimuistoista Brändimatkaa kanssasi,
                        jonka aikana <strong>opit ja oivallat</strong> miksi brändi on tärkeä, mihin kaikkeen brändi
                        vaikuttaa ja miten brändiä tulisi kehittää ja johtaa.
                    </p>
                    <p className='brandiuudistus-text-p'>
                        Monesti brändiä ajatellaan uudistuksen aikana vain asiakkaan näkökulmasta, mutta meille se ei riitä.
                        Siksi Brändimatkalla huomioidaan myös mahdolliset työntekijät, <strong>yhteistyökumppanit ja sijoittajat. </strong>
                        Sillä kyllä - yrityksenne brändi vaikuttaa myös heihin.
                    </p>
                    <p className='brandiuudistus-text-p'>
                        Pst! Kuuntele podcast -jaksomme:&nbsp;
                        <a href="https://open.spotify.com/episode/5BefXcVYPau4nxdm1t35T6" target="_blank" rel="noopener noreferrer" style={{color: '#000000'}}>
                            Mistä tietää pitäisikö tehdä brändiuudistus?
                        </a>
                    </p>

                    <Row className='d-flex'>
                        <Col xs='auto' className='d-flex align-items-center justify-content-start'>
                            <HashLink to="#tarina" className='brandiuudistus-tutustu-button btn btn-primary'>
                                Tutustu lisää<br />Brändihuvilaan
                            </HashLink>
                        </Col>
                        <Col className='d-lg-flex d-none align-items-center justify-content-start'>
                            <p className='brandiuudistus-lue-lisaa text-center my-0'>
                                <strong>Tai siirry lukemaan<br />lisää Brändimatkasta</strong>
                            </p>
                            <Image fluid
                                className='brändiuudistuskuva'
                                src={brändiuudistus}
                                style={{ width: '100px', height: 'auto', transform: 'rotate(120deg)', marginBottom: '-100px' }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="d-lg-flex d-none justify-content-center">
                    <Image
                        src={brändäyspalvelu}
                        className='brandiuudistus-image p-0' />
                </Col>
            </Row>
        </Container>
    )
}

export default Brandiuudistus