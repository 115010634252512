import './Header.css'
import video from '../../../assets/videos/finalvideo.mp4'
import logomusta from '../../../assets/images/logomusta.png'
import { HashLink } from 'react-router-hash-link';
import { Container, Row, Col, Image } from 'react-bootstrap'
import brändisuunnittelu from '../../../assets/images/brändisuunnittelu.jpg'
import brändistrategi from '../../../assets/images/brändistrategi.png'


function Header() {

    return (
        <Container fluid>
            <Col className="logocol d-none d-lg-block">
                <a href="/">
                    <Image
                        src={logomusta}
                        alt="Brändihuvila"
                        className="logo-image"
                    />
                </a>
            </Col>
            <Row className="align-items-center">
                <Col className="left-content text-center">
                    <h1 className="main-title mobile-top-space mobile-font-size-h1">
                        Uuden<br className="d-none d-lg-block" /> aikakauden<br />bränditoimisto
                    </h1>
                    <h2 className="sub-title-mobile d-lg-none mt-3">Lämpimästi tervetuloa Brändihuvilalle!</h2>
                    <div className='d-lg-flex d-none justify-content-center'>
                        <HashLink className='nav-item' to="#palvelumme">Palvelumme</HashLink>

                        <HashLink className='nav-item' to="#tarina">Tutustu meihin</HashLink>

                        <HashLink className='nav-item' to="#ota-yhteytta">Ota yhteyttä</HashLink>

                        <HashLink className='nav-item' to="#brandipodi">Brändipodi</HashLink>
                    </div>
                    <div className='d-lg-flex d-none justify-content-end header-arrow-container'>
                        <p className='header-arrow-text'>
                            Haluatko oppia lisää <br /> yritysbrändäyksestä?
                        </p>
                        <Image
                            src={brändistrategi}
                            style={{ width: '20%', height: 'auto', transform: 'rotate(-45deg)' }}
                        />
                    </div>
                </Col>
                <Col className="right-content d-none d-lg-flex justify-content-end align-items-center p-0">
                    <div className="text-image-container">
                        <h2 className="loop-animation sub-title m-0">Lämpimästi tervetuloa Brändihuvilalle!</h2>
                        <video
                            src={video}
                            loop
                            autoPlay
                            muted
                            className="header-video"
                            playsInline
                        ></video>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex d-lg-none justify-content-center align-items-center p-0 m-0'>
                    <Image
                        src={brändisuunnittelu}
                        className='kahvitarina-image-header'
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Header

