
import brändäyspodcast from '../../../assets/images/brändäyspodcast.png'
import soundbar from '../../../assets/videos/soundbar.gif'
import { Container, Row, Col, Image } from 'react-bootstrap'
import './Brandipodi.css'


function Brandipodi() {
    return (
        <Container fluid id='brandipodi'>
            <Row className='mb-5'>
                <h2 className='brandipodi-h2'>BrändiPodi: Podcast, jonka aiheena on ainoastaan brändäys</h2>
                <div className='d-lg-none d-flex flex-column justify-content-center align-items-center text-center'>
                    <Image src={soundbar}
                        className='soundbar-gif social-icon'
                    />
                    <Row>
                        <Col xs='auto'>
                            <a href="https://open.spotify.com/show/5DwXSifGs3nAptW5nfhREe" target="_blank" rel="noopener noreferrer">
                                <i class="fa-brands fa-spotify fa-3x social-icon"></i>
                            </a>
                        </Col>
                        <Col xs='auto'>
                            <a href="https://podcasts.apple.com/us/podcast/br%C3%A4ndipodi/id1781294973" target="_blank" rel="noopener noreferrer">
                                <i class="fa-solid fa-podcast fa-2x social-icon"></i>
                            </a>
                        </Col>
                        <Col xs='auto'>
                            <a href="https://www.youtube.com/playlist?list=PL4ejYhls9m1HaAZLNCkpcmQD1_cL4nr2q" target="_blank" rel="noopener noreferrer">
                                <i class="fa-brands fa-youtube fa-3x social-icon"></i>
                            </a>
                        </Col>
                    </Row>
                    <div className='brandipodi-text my-5'>
                        <p>Brändihuvilan tuottama BrändiPodi on podcast, jossa puhutaan ainoastaan brändäyksestä. Podcastia juontaa Brändihuvilan perustaja, omistaja ja yritysten oma, vieraileva brändistrategi Jenni.</p>
                        <p>Jaksot ovat alle 15 minuutin mittaisia, ja voit kuunnella BrändiPodia täysin ilmaiseksi Spotifyssa, Apple Podcastissa tai YouTubessa.</p>
                        <p>Opi uutta brändin rakentamisesta ja tule brändäyksen asiantuntijaksi.</p>
                    </div>
                    <a href="https://open.spotify.com/show/5DwXSifGs3nAptW5nfhREe" target="_blank" rel="noopener noreferrer">
                        <Image
                            src={brändäyspodcast}
                            className="header-image-brandipodi"
                        />
                    </a>
                </div>
            </Row>
            <Row>
                <Col className="left-content-brandipodi d-lg-flex flex-column d-none">
                    <div className='brandipodi-text'>
                        <p>Brändihuvilan tuottama podcast BrändiPodi on podcast, jossa puhutaan ainoastaan brändäyksestä. Podcastia juontaa Brändihuvilan perustaja, omistaja ja yritysten oma, vieraileva brändistrategi Jenni.</p>
                        <p>Jaksot ovat alle 15 minuutin mittaisia, ja voit kuunnella BrändiPodia täysin ilmaiseksi Spotifyssa, Apple Podcastissa tai YouTubessa.</p>
                        <p>Opi uutta brändin rakentamisesta ja tule brändäyksen asiantuntijaksi.</p>
                    </div>
                    <Row className='icon-row'>
                        <Col xs='auto'>
                            <a href="https://open.spotify.com/show/5DwXSifGs3nAptW5nfhREe" target="_blank" rel="noopener noreferrer">
                                <i class="fa-brands fa-spotify fa-3x social-icon"></i>
                            </a>
                        </Col>
                        <Col xs='auto'>
                            <a href="https://podcasts.apple.com/us/podcast/br%C3%A4ndipodi/id1781294973" target="_blank" rel="noopener noreferrer">
                                <i class="fa-solid fa-podcast fa-2x social-icon"></i>
                            </a>
                        </Col>
                        <Col xs='auto'>
                            <a href="https://www.youtube.com/playlist?list=PL4ejYhls9m1HaAZLNCkpcmQD1_cL4nr2q" target="_blank" rel="noopener noreferrer">
                                <i class="fa-brands fa-youtube fa-3x social-icon"></i>
                            </a>
                        </Col>
                    </Row>
                    <Col className='soundbar-col'>
                        <Image src={soundbar}
                            className='soundbar-gif social-icon'
                        />
                    </Col>
                </Col>
                <Col className="d-lg-flex d-none justify-content-center">
                    <a href="https://open.spotify.com/show/5DwXSifGs3nAptW5nfhREe" target="_blank" rel="noopener noreferrer">
                        <Image
                            src={brändäyspodcast}
                            className="header-image-brandipodi"
                        />
                    </a>
                </Col>
            </Row>
        </Container>
    )
}

export default Brandipodi