import { Card } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'
import './Services.css'

function ServiceBlock({ image, title, description, price, buttonText, centerImageTop = true, link }) {
    return (
<Card className="p-0" style={{ maxWidth: '400px', border: 'none', backgroundColor: '#fffcf7'}}>
            <Card.Img 
                variant="top" 
                src={image} 
                alt={title} 
                className="mb-3" 
                style={{
                    objectPosition: centerImageTop ? 'top' : 'center', 
                    objectFit: 'cover', 
                    height: '200px', 
                    width: '100%', 
                    borderRadius: '10px'
                }} 
            />
            <Card.Body>
                <Card.Title className="mb-0 service-card-title" style={{ minHeight: '50px', color: '#000000' }}>{title}</Card.Title>
                <Card.Text className="mb-0 service-card-text" style={{ minHeight: '190px' }}>
                    {description}
                </Card.Text>
                <Card.Text className="mb-3 service-card-price">
                    <strong>{price}</strong>
                </Card.Text>
                <HashLink to={link} className="btn btn-primary lue-lisaa-button">{buttonText}</HashLink>
            </Card.Body>
        </Card>
    )
}

export default ServiceBlock