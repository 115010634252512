import './TopBar.css';
import { Container, Row, Col } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

function TopBar() {
    return (
        <div className="topBar text-center">
            <Container fluid>
                <Row>
                    <Col className="loop-animation2">
                        {/* Lista, joka sisältää bulletit */}
                        <ul className="topBarText">
                            <li className="textItem">
                                Vain 9 asiakaspaikkaa vuodelle 2025.{' '}
                                <HashLink to="#ota-yhteytta" style={{ color: '#fffcf7' }}>
                                    Varaa tästä
                                </HashLink>
                            </li>
                            <li className="textItem">
                                Vain 9 asiakaspaikkaa vuodelle 2025.{' '}
                                <HashLink to="#ota-yhteytta" style={{ color: '#fffcf7' }}>
                                    Varaa tästä
                                </HashLink>
                            </li>
                            <li className="textItem">
                                Vain 9 asiakaspaikkaa vuodelle 2025.{' '}
                                <HashLink to="#ota-yhteytta" style={{ color: '#fffcf7' }}>
                                    Varaa tästä
                                </HashLink>
                            </li>
                            <li className="textItem">
                                Vain 9 asiakaspaikkaa vuodelle 2025.{' '}
                                <HashLink to="#ota-yhteytta" style={{ color: '#fffcf7' }}>
                                    Varaa tästä
                                </HashLink>
                            </li>
                            <li className="textItem">
                                Vain 9 asiakaspaikkaa vuodelle 2025.{' '}
                                <HashLink to="#ota-yhteytta" style={{ color: '#fffcf7' }}>
                                    Varaa tästä
                                </HashLink>
                            </li>
                            <li className="textItem">
                                Vain 9 asiakaspaikkaa vuodelle 2025.{' '}
                                <HashLink to="#ota-yhteytta" style={{ color: '#fffcf7' }}>
                                    Varaa tästä
                                </HashLink>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default TopBar;


